// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}
// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

//   .workpackageRows {
//       border-top: 1px solid lightgray;
//   }

// styling for pdf document in unpaid leave request
.react-pdf__Document {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}
.react-pdf__Page__textContent {
  display: none;
}

// removing padding from tabs component in administration settings -> permissions
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 17px;
}

// privileges column headers
.privilege-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 44px;
}
.privilege-options {
  right: 0;
  position: absolute;
}
.privilege-title {
  margin: 0 auto;
  text-align: center;
}

.ant-transfer-list-content-item-remove {
  visibility: hidden;
}

.ant-modal-close {
  visibility: hidden;
}

.employee-birth-date-fitler .ant-picker-year-btn {
  display: none;
}

.team-pending-timesheets .highlighted-timesheet {
  background-color: rgb(255,243,202) !important;
}

.team-pending-timesheets .highlighted-timesheet .ant-table-cell-row-hover {
  background-color: rgb(255,243,202) !important;
}

.custom-notification-list-item:hover {
  background-color: #d9d9d9;
  cursor: pointer;
}

.notification-list-items .ant-list-item:hover {
    background-color: #d9d9d9;
    cursor: pointer;
}

.notification-list-items .ant-list-item-meta-title {
  margin: 0;
}

button.refresh-pulse-button:not(:disabled) {
  box-shadow: 0 0 0 0 #1890ff;
	transform: scale(1);
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #9bceff;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px #badaf8;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #fbfcfd;
	}
}
.budget-editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.budget-editable-cell-value-wrap:hover {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
div.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}
table.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}
.jan_dec_div{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #342f2f;
  border-radius: 0.625rem;
  padding: 20px;
}
.jan_delte_inside_map{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.select_year{
  margin-right: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.188rem;
  color: black;
  width: 6rem;
  min-width: 6rem;
}
.select_year:disabled{
  color: black;
}
.months{
  text-align: center;
  // margin-bottom: 0.625rem;
  // width: 6%;
  
  
}
.month-input{
  width: 3.15rem;
  height: 1.5rem;
  //border-color: #1890ff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  //border: 5px solid #fafafa
  
}
.month-input:disabled{
  width: 3.15rem;
  height: 1.5rem;
  //border-color: #1890ff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #f5f5f5;
  
  //border: 5px solid #fafafa
  
}
.totalHour_TotalCost_edit_add_delete{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  
}
.Total_Hours{
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  width: 5rem;
}
.Total_cost{
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
}
.Edit_Icon{
  width: 1.25rem;
  margin-left: 1.75rem;
  margin-top: 2.5rem;
  color: #1890ff;
}
.plus_Icon{
  color: #1890ff;
  width: 0.675rem;
  margin-left: 1.75rem;
  margin-top: 2.5rem;

}
.total-input-hours{
  width: 4rem;
  height: 1.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
}

.total-input-hours:disabled{
  width: 4rem;
  height: 1.5rem;
  //border-color: #1890ff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #f5f5f5;
  
}
.total-input-cost:disabled{
  width: 5rem;
  height: 1.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #f5f5f5;
  
}
.total-input-cost{
  width: 5rem;
  height: 1.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
}
.ant-table{
  background-color: #fafafa;
}
.ant-table-row-expand-icon-cell
.ant-table-row-expand-icon {
  display: inline-flex;
  float: left;
  vertical-align: sub;
}

//for + icon 
.ant-table-cell 
.ant-table-row-expand-icon-cell{
  width: 10%;
  background-color: red;
}

//switch
.switch{
  display: flex;
  flex-direction: row-reverse;
  margin-right: 15%;
  
}
.Switch_parent{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
//Spin In project for loading
.SpinProject{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.Send_data_div{
  display: flex;
  flex-direction: row-reverse;
}


.css_width_budget{
  width:100%;
}

#navDrrwaer{
z-index: 1;
}

.Clock:hover{
  stroke: #D13e08;
  }
